import { Strings } from "constants/strings";
import * as yup from "yup";

/* eslint-disable */
const passwordRegExp =
  /^.*(?=.{8,})(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*\(\)\-\=\_\+\`\~\.\,\<\>\/\?\;\:\'\"\\\|\[\]\{\}]).*$/;
// const numberRegExp = /^[0-9.,]*$/;

const emailCommonSchema = yup.string().email(Strings.inputs.email.error);
const requiredError = Strings.inputs.requiredError.error;
const passwordSchema = yup
  .string()
  .matches(passwordRegExp, Strings.inputs.password.error);

export const defaultConfirmPassword = (fieldName: string) =>
  yup
    .string()
    .oneOf([yup.ref(fieldName), undefined], Strings.inputs.password.mismatch);

export const LoginSchema = yup.object().shape({
  email: emailCommonSchema.required(requiredError),
  password: yup.string().required(requiredError),
});

export const RecoverSchema = yup.object().shape({
  email: emailCommonSchema.required(requiredError),
});

export const ActivateAccountSchema = yup.object().shape({
  firstname: yup.string().required(requiredError),
  lastname: yup.string().required(requiredError),
  // password: yup
  //   .string()
  //   .required("La contraseña es requerida")
  //   .min(8, "La contraseña debe tener al menos 8 caracteres")
  //   .test(
  //     "uppercase-number",
  //     "La contraseña debe contener al menos una mayúscula y una minúscula",
  //     (value) => {
  //       const upperCaseRegex = /[A-Z]/;
  //       const lowerCase = /[a-z]/;
  //       return upperCaseRegex.test(value) && lowerCase.test(value);
  //     }
  //   ),
  // confirmPassword: yup
  //   .string()
  //   .oneOf([yup.ref("password"), undefined], "Las contraseñas deben coincidir")
  //   .required(requiredError),
});

export const EditPasswordSchema = yup.object().shape({
  password: yup.string().required(requiredError),
  newPassword: yup
    .string()
    .required("La contraseña es requerida")
    .test(
      "uppercase-number",
      "La contraseña debe contener al menos una mayúscula y una minúscula",
      (value) => {
        const upperCaseRegex = /[A-Z]/;
        const lowerCase = /[a-z]/;
        return upperCaseRegex.test(value) && lowerCase.test(value);
      }
    )
    .min(8, "La contraseña debe tener al menos 8 caracteres"),
  repeatPassword: yup
    .string()
    .required("La confirmación de contraseña es requerida")
    .oneOf([yup.ref("newPassword")], "Las contraseñas deben coincidir"),
});

export const NewPasswordValidationSchema = yup.object().shape({
  password: passwordSchema.required(requiredError),
  repeatNewPassword: defaultConfirmPassword("password").required(requiredError),
});
