import { ApiResponse } from "apisauce";
import { Api } from "./api";
import { GeneralApiProblem, getGeneralApiProblem } from "./api-problem";

export interface ApiListResponse<T> {
  data: T[];
}

export interface Invitation {
  nombre: string;
  apellido: string;
  password: string;
}

export class InviteApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  //   async sendInvite(newGuestUser: { rol: string; email: string }): Promise<any> {
  //     try {
  //       const response: ApiResponse<never> = await this.api.apisauce.post(
  //         `/invitaciones/invitar`,
  //         newGuestUser
  //       );
  //       if (!response.ok) {
  //         const problem = getGeneralApiProblem(response);
  //         if (problem) return problem;
  //       }

  //       const { data } = response;
  //       if (data) return data;
  //       return response;
  //     } catch (e) {
  //       return { kind: "bad-data" };
  //     }
  //   }

  async getInvitation(token: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/invitacion/${token}`
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async activateInvitation(token: string, body: Invitation): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/invitacion/${token}/activar`,
        body
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}
