import { ApiResponse } from "apisauce";
import { Api } from "./api";
import { getGeneralApiProblem } from "./api-problem";

const shouldMock = false;
export class CommunityApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getDashboardsList(type: string): Promise<any> {
    try {
      // if (shouldMock) {
      //   return new Promise((resolve, reject) => {
      //     resolve(dinamicComunity);
      //   });
      // }

      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/benchmark/dashboards?tipo=${type}`
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getDashboard(id: number): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/dashboards/${id}`
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}
