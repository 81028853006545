import React, { useState } from "react";
import { Box, TextField, Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RecoverSchema } from "utils/validations.schema";
import api from "services/api";

type PayloadRecupero = {
  email: string;
};

const RecuperarCuentaForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RecoverSchema),
    defaultValues: {
      email: "",
    },
  });
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async (data: PayloadRecupero) => {
    setIsSubmitting(true)
    const { email } = data;
    const response = await api.passwords.recoverAccount({ email });
    setIsSubmitting(false)
    if (response.result && response.result === "ok") {
      navigate("/portal/solicitud-recibida", { state: { email } });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" gap="16px" mb="16px">
        <TextField
          id="email"
          label="Dirección de email"
          type="email"
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          {...register("email")}
        />
      </Box>
      <Button type="submit" variant="success" color="primary" disabled={isSubmitting}>
      {
        isSubmitting ? (
          <Box sx={{ marginRight: '5px', width: '110px' }}>
            <CircularProgress size='25px' color="inherit" />
          </Box>
        )
        :
        'Enviar Solicitud'
      }
      </Button>
    </form>
  );
};

export default RecuperarCuentaForm;
