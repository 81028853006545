import React, { useState } from "react";
import { IconButton } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
import { colors } from "utils/colors";
import { DrawerHeaderNav, DrawerNav } from "./index.styled";
import AgroViewLogo from "../../assets/agroview_logo.svg";
import { observer } from "mobx-react";
import NavAccount from "./NavAccount";
import NavList from "./NavList";

const Nav: React.FC = () => {
  const [isOpen, setOpen] = useState(true);

  return (
    <DrawerNav open={isOpen} variant="permanent">
      <DrawerHeaderNav open={isOpen}>
        {isOpen && <img src={AgroViewLogo} alt="logo" />}
        <IconButton
          aria-label="open drawer"
          onClick={() => setOpen(!isOpen)}
          disableRipple
          sx={{
            padding: "0px !important",
            ":hover": {
              backgroundColor: "transparent !important",
              color: colors.primary.lightGreen,
            },
          }}
        >
          {!isOpen && <MenuIcon />}
          {isOpen && <MenuOpenIcon />}
        </IconButton>
      </DrawerHeaderNav>

      <NavAccount open={isOpen} />

      <NavList open={isOpen} />
    </DrawerNav>
  );
};

export default observer(Nav);
