import React from "react";
import { Box, ListItemButton } from "@mui/material";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import { useNavigate } from "react-router";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";
import { Title } from "components/Typography";
import { Item } from "models/nav";
import { colors } from "utils/colors";
import Tooltip from "components/commons/Toooltip";

interface INavItem {
  open: boolean;
  item: Item;
}

const NavItem: React.FC<INavItem> = observer(({ open, item }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <ListItemButton
      onClick={() => {
        navigate(item.urlFront);
      }}
      sx={{
        display: "flex",
        justifyContent: open ? "flex-start" : "center",
        gap: 1,
      }}
      id={item.htmlTag || "dashboard"}
    >
      {open ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Title
            preset="T6"
            color={
              pathname === item.urlFront
                ? colors.primary.darkGreen
                : colors.neutral.black
            }
          >
            {item.nombre}
          </Title>
          {pathname === item.urlFront && (
            <ArrowCircleRightTwoToneIcon
              sx={{ color: colors.primary.darkGreen, fontSize: "26px" }}
            />
          )}
        </Box>
      ) : (
        <Tooltip title={item.nombre}>
          {pathname === item.urlFront ? (
            <ArrowCircleRightTwoToneIcon
              sx={{ color: colors.primary.darkGreen, fontSize: "26px" }}
            />
          ) : (
            <Box width="24px" height="24px">
              <img
                src={item.logo}
                alt={item.nombre}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          )}
        </Tooltip>
      )}
    </ListItemButton>
  );
});

export default NavItem;
