/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import api from "services/api";
import { Strings } from "constants/strings";
import { capitalLetter, checks, lessEigthChar, lowercaseLetter, pipe, validationsRules } from "utils/utilities";
import { TooltipPassword } from "components/commons/tooltipPassword";
import { ChekcListPass } from "components/commons/checkList";

interface EditPasswordFormProps {
  setOpen: () => void;
}
const EditPasswordForm = ({ setOpen }: EditPasswordFormProps) => {
  const [showPassword, setShowPassword] = useState({
    password: false,
    newPassword: false,
    repeatPassword: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [valuePass, setValuePass] = useState<string>('');
  const [checkListArr, setCheckListArr] = useState<any[]>(checks);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const onSubmit = async (values: any) => {
    setIsSubmitting(true)
    if (values.newPassword === values.repeatPassword) {
      const body = {
        oldPassword: values.password,
        newPassword: values.newPassword,
      };
      const response = await api.passwords.changePassword(body);

      if (!response.kind) {
        enqueueSnackbar(Strings.notifications.success, {
          variant: "success",
        });
        setOpen();
      } else if (
        response.kind === "bad-data" ||
        response.kind === "unauthorized"
      ) {
        enqueueSnackbar(Strings.notifications.commonError, {
          variant: "error",
        });
      }
    }
    setIsSubmitting(false)
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      password: "",
      newPassword: "",
      repeatPassword: "",
    },
  });

  const handleClickShowPassword = (
    value: "password" | "newPassword" | "repeatPassword"
  ) => {
    setShowPassword((state) => {
      return { ...state, [value]: !state[value] };
    });
  };

  const handleFocus = () => {
    setOpenPopUp(true);
  };
  const handleBlur = () => {
    setOpenPopUp(false);
  };

  const handleMatch = () => {
    const iterable = pipe(
      lowercaseLetter,
      capitalLetter,
      lessEigthChar,
    )(valuePass);

    const checksListMatched = checks.map((e) => {
      const match = iterable.forEach((v: any) => {
        if (v.key === e.key) return (e.checked = v.checked);
      });
      return { ...e };
    });
    return checksListMatched;
  };

  const handleChange = (value: string) => {
    setValuePass(value);
  }

  useEffect(() => {
    setCheckListArr(handleMatch());
  }, [valuePass]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 40px",
          marginTop: "20px",
        }}
      >
          <TextField
            id="password"
            sx={{ mb: "20px" }}
            label="Contraseña actual"
            placeholder="Contraseña actual"
            type={showPassword.password ? "text" : "password"}
            error={Boolean(errors.password)}
            helperText={errors.password ? String(errors.password?.message) : ""}
            {...register("password", { required: {value: true, message: 'Este campo es requerido'},  })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("password")}
                    edge="end"
                  >
                    {showPassword.password ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

        <Controller 
          control={control}
          name="newPassword"
          defaultValue=""
          rules={validationsRules}
          render={({ field: { value, onChange }}) => (
            handleChange(value),
            <TooltipPassword
            open={openPopUp}
            onClose={() => setOpenPopUp(false)}
            onOpen={() => setOpenPopUp(true)}
            title={<ChekcListPass checksList={checkListArr} />}
            placement='left-start'
            arrow
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
          <TextField
            onFocus={handleFocus}
            value={value}
            id="newPassword"
            sx={{ mb: "20px" }}
            label="Nueva contraseña"
            placeholder="Nueva contraseña"
            type={showPassword.newPassword ? "text" : "password"}
            error={Boolean(errors.newPassword)}
            helperText={
              errors.newPassword ? String(errors.newPassword?.message) : ""
            }
            {...register("newPassword")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("newPassword")}
                    edge="end"
                  >
                    {showPassword.newPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              onBlur: () => handleBlur(),
              onChange: onChange,
              onFocus: () => handleFocus(),
            }}
          />
          </TooltipPassword>

          )}
          />
          <TextField
            id="repeatPassword"
            sx={{ mb: "20px" }}
            label="Repetir nueva contraseña"
            placeholder="Repetir nueva contraseña"
            type={showPassword.repeatPassword ? "text" : "password"}
            error={Boolean(errors.repeatPassword)}
            helperText={
              errors.repeatPassword
                ? String(errors.repeatPassword?.message)
                : ""
            }
            {...register("repeatPassword", { 
              required: {value: true, message: 'Este campo es requerido'}, 
              validate: (value) => valuePass === value || 'Las contraseñas no coinciden'
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("repeatPassword")}
                    edge="end"
                  >
                    {showPassword.repeatPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "8px",
          marginBottom: "40px",
        }}
      >
        <Button
          type="button"
          variant="critic"
          color="secondary"
          sx={{ padding: "12px" }}
          onClick={() => setOpen()}
        >
          Descartar
        </Button>
        <Button
          type="submit"
          variant="success"
          color="primary"
          sx={{ padding: "12px" }}
          disabled={isSubmitting}
        >
          {
            isSubmitting ? (
              <Box sx={{ marginRight: '5px', width: '110px' }}>
                <CircularProgress size='25px' color="inherit" />
              </Box>
            )
            :
            'Guardar cambios'
          }
        </Button>
      </Box>
    </form>
  );
};

export default EditPasswordForm;
