import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { useRollbar, Provider as RollbarProvider } from '@rollbar/react';
import rollbarConfig from '../../utils/rollbar-config';

export default {
  success: function (msg: string) {
    this.toast(msg, 'success', null);
  },
  error: function (msg: string) {
    this.toast(msg, 'error', null);
  },
  unhandledError: function (msg: string) {
    this.toast(
      'Estamos teniendo inconvenientes para realizar esta operación.',
      'error',
      msg,
    );
  },

  toast: function (msg: string, variant:any, originalError:any) {
    const Display = withSnackbar((props:any) => {
      const { message, enqueueSnackbar } = props;
      const rollbar = useRollbar();

      if (variant == 'error') {
        if (typeof originalError === 'string') {
          rollbar.error(originalError);
        } else if (originalError instanceof Error) {
          rollbar.error(originalError.message);
        }
      }
      enqueueSnackbar(message, { variant });
      return null;
    });
    const mountPoint = document.getElementById('snackbarhelper');
    ReactDOM.render(
      <RollbarProvider config={rollbarConfig}>
        <SnackbarProvider
          preventDuplicate
          maxSnack={3}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <Display message={msg} variant={variant} />
        </SnackbarProvider>
      </RollbarProvider>,
      mountPoint,
    );
  },
};
