import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { colors } from "utils/colors";
import { Paragraph, Title } from "components/Typography";

const SolicitudRecibida: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (!location.state?.email) {
    navigate("/portal/iniciar-sesion");
  }

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <Title preset="T2">Solicitud recibida</Title>
      <Paragraph preset="P1">
        Si el mail{" "}
        <span style={{ fontWeight: "bold" }}>{location.state?.email}</span>{" "}
        corresponde a una cuenta válida, dentro de los próximos minutos
        recibirás un email de AgroView para recuperar el acceso.
      </Paragraph>
      <Box
        onClick={() => navigate("/portal/iniciar-sesion")}
        display="flex"
        alignItems="center"
        marginTop="8px"
        sx={{
          cursor: "pointer",
        }}
      >
        <ArrowBack
          fontSize="small"
          sx={{ color: colors.primary.lightGreen, mr: "6px", mt: "3px" }}
        />
        <Title preset="T6" color={colors.primary.lightGreen}>
          Volver a iniciar sesión
        </Title>
      </Box>
    </Box>
  );
};

export default SolicitudRecibida;
