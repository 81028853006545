import React, { useRef, useState } from "react";
import {
  Box,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchema } from "utils/validations.schema";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { colors } from "utils/colors";
import { Strings } from "constants/strings";
import api from "services/api";
import { useStores } from "store/root-store/root-store-context";
import { observer } from "mobx-react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type FormSesion = {
  email: string;
  password: string;
};

const IniciarSesionForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const navigate = useNavigate();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { sessionStore } = useStores();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: FormSesion) => {
    setIsLoading(true)
    const token = (await recaptchaRef?.current?.executeAsync()) || "";
    recaptchaRef?.current?.reset();

    const response = await api.auth.getToken(data.email, data.password, token);

    if (response.status && response.status === 400) {
      enqueueSnackbar(Strings.forms.login.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      setIsLoading(false)
    }

    if (response.token) {
      sessionStore.setToken(response.token);
      api.api.setToken(response.token);
      enqueueSnackbar(Strings.forms.login.success, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      const res = await api.common.getMenuSections();
      if (res) {
        setIsLoading(false)
        navigate(`${res[0].items[0].urlFront}`, {
          state: res[0].items[0].tablero,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" gap="16px">
        <TextField
          id="email"
          label="Dirección de email"
          type="email"
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          {...register("email")}
        />

        <FormControl>
          <TextField
            id="password"
            sx={{ mb: "20px" }}
            label="Contraseña actual"
            placeholder="Contraseña actual"
            type={showPassword ? "text" : "password"}
            error={Boolean(errors.password)}
            helperText={errors.password ? String(errors.password?.message) : ""}
            {...register("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

        <ReCAPTCHA
          sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
          ref={recaptchaRef}
          size="invisible"
          hl="es"
        />
      </Box>
      <Box mt="16px">
        <Button disabled={isLoading} type="submit" variant="success" color="primary">
          {
            isLoading ? (
              <Box sx={{ marginRight: '5px', width: '60px' }}>
                <CircularProgress size='25px' color="inherit" />
              </Box>
            )
            :
            'Ingresar'
          }
        </Button>
        <Link
          to="/portal/recuperar-cuenta"
          style={{ color: colors.primary.lightGreen, marginLeft: "16px" }}
        >
          Olvidé mi contraseña
        </Link>
      </Box>
    </form>
  );
};

export default observer(IniciarSesionForm);
