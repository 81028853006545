import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import PortalLayout from "components/Layouts/Portal";
import Login from "screen/portal/iniciarSesion";
import RecuperarCuenta from "screen/portal/recuperarCuenta";
import SolicitudRecibida from "screen/portal/solicitudRecibida";
import ActivarCuentaScreen from "screen/portal/activarCuenta";
import GenerarContrasena from "screen/portal/generarContrasena";
import RecuperarVerificar from "screen/portal/recuperarVerificar";

const Portal: React.FC = () => {
  return (
    <PortalLayout>
      <Routes>
        <Route path="/iniciar-sesion" element={<Login />} />
        <Route path="/recuperar-cuenta" element={<RecuperarCuenta />} />
        <Route path="/solicitud-recibida" element={<SolicitudRecibida />} />
        <Route path="/invitacion" element={<ActivarCuentaScreen />} />
        <Route path="/generar-contraseña" element={<GenerarContrasena />} />
        <Route path="/recuperar-verificar" element={<RecuperarVerificar />} />
        <Route path="*" element={<Navigate to="/portal/iniciar-sesion" />} />
      </Routes>
    </PortalLayout>
  );
};

export default Portal;
