import React from "react";
import { Box, IconButton, Card, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Title } from "components/Typography";
import { colors } from "utils/colors";
import EditPasswordForm from "components/Forms/editPassword";

interface EditPasswordProps {
  open: boolean;
  setOpen: (a: boolean) => void;
  handleCloseMenu: () => void;
}

const EditPassword: React.FC<EditPasswordProps> = ({
  open,
  setOpen,
  handleCloseMenu,
}) => {
  const onHandleClose = () => {
    setOpen(false);
    handleCloseMenu();
  };

  const stopPropagationForTab = (event: any) => {
    event.stopPropagation();
  };

  return (
    <Modal
      open={open}
      onClose={onHandleClose}
      aria-labelledby="modal-modal-title"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      aria-describedby="modal-modal-description"
      onKeyDown={stopPropagationForTab}
    >
      <Card sx={{ width: "560px" }}>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <IconButton
            aria-label="close modal"
            onClick={onHandleClose}
            sx={{
              alignSelf: "flex-end",
              marginRight: "5px",
              color: colors.primary.lightGreen,
            }}
            edge="end"
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              marginLeft: "40px",
              marginBottom: "8px",
              display: "flex",
            }}
          >
            <Title preset="T1" color={colors.primary.darkGreen}>
              Mi contraseña
            </Title>
          </Box>
          <EditPasswordForm setOpen={onHandleClose} />
        </Box>
      </Card>
    </Modal>
  );
};
export default EditPassword;
