import React from "react";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import MenuProfile from "components/MenuProfile";
import { Title } from "components/Typography";
import { useStores } from "store/root-store/root-store-context";
import { pxToRem } from "utils/metrics";
import { colors } from "utils/colors";

const NavAccount: React.FC<{ open: boolean }> = observer(({ open }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const { sessionStore } = useStores();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        p={pxToRem(16)}
        display="flex"
        justifyContent={open ? "flex-start" : "center"}
        alignItems="center"
        gap="7px"
        onClick={handleClick}
        sx={{
          userSelect: "none",
          cursor: "pointer",
          color: colors.primary.darkGreen,
          "&:hover > *": {
            color: `${colors.primary.lightGreen} !important`,
          },
        }}
      >
        <AccountBoxTwoToneIcon />
        {open && (
          <Title preset="T6" color="inherit">
            {sessionStore.fullName}
          </Title>
        )}
      </Box>
      <MenuProfile anchorEl={anchorEl} open={openMenu} onClose={handleClose} />
    </>
  );
});

export default NavAccount;
