import React from "react";
import { Box } from "@mui/material";
import { Paragraph, Title } from "components/Typography";
import { colors } from "utils/colors";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface IErrorBoundaryUI {
  error: Error;
  resetError: () => void;
}

const ErrorBoundaryUI = ({ error, resetError }: IErrorBoundaryUI) => {
  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "block",
      }}
    >
      {import.meta.env.VITE_ENVIRONMENT === "development" ? (
        <Box
          sx={{
            width: "calc(100% - 24px)",
            minHeight: "calc(100% - 24px)",
            padding: "12px",
          }}
        >
          <details>
            <summary style={{ cursor: "pointer" }}>
              Detalles del error (esto es solo visible en el ambiente de
              desarrollo)
            </summary>
            <h3>{error.name}</h3>
            <h4>{error.message}</h4>
            <i>Tip: En la consola pueden verse más detalles del error!</i>
            <div style={{ lineHeight: "28px", marginTop: "24px" }}>
              {error.stack &&
                error.stack.split(" at ").map((item, idx) => (
                  <p key={`error-${idx + 1 - 1}`}>
                    {idx > 0 && <>&emsp;&emsp;&emsp;at </>}
                    {item}
                  </p>
                ))}
            </div>
          </details>
        </Box>
      ) : (
        <Box
          sx={{
            width: "calc(100% - 24px)",
            minHeight: "calc(100vh - 24px)",
            padding: "12px",
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
            gap: "14px",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            bgcolor: colors.neutral.black30,
          }}
        >
          <Box
            sx={{
              bgcolor: "white",
              width: "100%",
              maxWidth: "600px",
              padding: "40px",
            }}
          >
            <WarningAmberIcon sx={{ fontSize: "44px" }} />
            <Title preset="T3">Fuera de servicio</Title>
            <Box sx={{ marginTop: "10px" }}>
              <Paragraph preset="P2">{error.message}</Paragraph>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ErrorBoundaryUI;
