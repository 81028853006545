import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import ActivarCuentaForm from "components/Forms/activarCuenta";
import { Paragraph, Title } from "components/Typography";
import { colors } from "utils/colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import api from "services/api";
import { Strings } from "constants/strings";
interface IinvitationInfo {
  email: string;
  cuenta: string;
}

const ActivarCuentaScreen: React.FC = () => {
  const [invitationInfo, setInvitationInfo] = useState<IinvitationInfo>({
    cuenta: "",
    email: "",
  });

  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const token = searchParams.get("codigo")?.split("?")[0];

  const getUserEmailInvitation = async () => {
    if (token) {
      const response = await api.invite.getInvitation(token);
      if (response.kind === "token-not-found-or-invalid") {
        enqueueSnackbar(Strings.notifications.expiredTokenInvite, {
          variant: "error",
        });
        navigate("/portal/iniciar-sesion", { replace: true });
      }
      const userDetail = { ...response };
      setInvitationInfo(userDetail);
    }
  };

  useEffect(() => {
   getUserEmailInvitation();
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <Title preset="T2">Activá tu cuenta</Title>
      <Paragraph preset="P1">
        Generá las credenciales de tu cuenta para acceder a la plataforma.
      </Paragraph>
      <Box my="24px">
        <Paragraph preset="P4">Cuenta</Paragraph>
        <Title preset="T6" color={colors.primary.darkGreen}>
          {invitationInfo?.cuenta}
        </Title>
      </Box>
      <ActivarCuentaForm
        emailInvitation={invitationInfo?.email}
        token={token}
      />
    </Box>
  );
};

export default ActivarCuentaScreen;
