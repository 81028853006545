import { types, Instance, SnapshotOut } from "mobx-state-tree";

export const UserDataType = types.model("UserData").props({
  cuentaActual: types.maybeNull(types.number),
  email: types.maybeNull(types.string),
  fechaActivacion: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  id: types.number,
  lastName: types.maybeNull(types.string),
  telefono: types.maybeNull(types.string),
  cuentaNombre: types.maybeNull(types.string),
});
type UserDataModelType = Instance<typeof UserDataType>;
export type UserData = UserDataModelType;

export const UserType = types.model("UserType").props({
  modifiedAt: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
});

export const UserModel = types.model("User").props({
  id: types.maybeNull(types.string),
});

type UserModelType = Instance<typeof UserModel>;
export type User = UserModelType;
type UserSnapshotType = SnapshotOut<typeof UserModel>;
export type UserSnapshot = UserSnapshotType;
export const createUserDefaultModel = () => types.optional(UserModel, {});
