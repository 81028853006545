export const Strings = {
    notifications: {
      tokenNotFoundOrInvalid: 'La solicitud de recuperación de contraseña expiró',
      expiredTokenInvite: 'La invitación expiró',
      invalidToken:
        'Cerramos tu sesión ya que detectamos el uso de la aplicación desde otro dispositivo.',
      expiredToken:
        'Por tu seguridad, tu sesión ha expirado por inactividad, por favor accedé nuevamente.',
      invalidUser:
        'Ocurrió un problema al acceder a tu cuenta, si el error persiste comunícate con nosotros.',
      success: 'Se ha realizado con éxito la operacion solicitada',
      commonError:
        'Estamos teniendo inconvenientes para realizar esta operación.',
      resendedInviteOk: 'Reenviamos la invitación',
      resendedInviteError: 'No pudimos reenviar la invitación',
      validateFromErpSucces: 'Solicitud procesada con éxito...',
    },
    inputs: {
      requiredError: { error: 'Este campo es obligatorio' },
      phoneNumber: {
        helper: 'Ingresá tu número sin el 15 y con código de área (11)',
        error: 'Revisá el número telefónico',
      },
      password: {
        error:
          'La contraseña no cumple con los requisitos mínimos',
        mismatch: 'Las contraseñas no coinciden.',
      },
      hectareas: {
        declaredCannotBeGreater:
          'Las hectáreas declaradas no pueden ser mayores que las aprobadas',
        declaredCannotLessThanOne:
          'Las hectáreas declaradas no pueden ser menores a 1',
      },
      email: {
        error: 'El formato del email ingresado es inválido',
      },
      min3Error: {
        error: 'Debe tener al menos 3 caracteres',
      },
      min10Error: {
        error: 'Debe tener al menos 10 caracteres',
      },
      max5000Error: {
        error: 'No puede tener mas de 5000 caracteres',
      },
      max50Error: {
        error: 'No puede tener mas de 50 caracteres',
      },
      paísError: {
        error: 'Debe elegir un país',
      },
      nifError: {
        error: 'El NIF es necesario',
      },
    },
    forms: {
      fieldModification: {
        success: 'Se han guardado los datos con exito',
      },
      login: {
        error: 'Por favor verificá tu usuario o contraseña ingresados',
        success: 'Sesión iniciada con éxito',
      },
      finance: {
        error: {
          requiredError: 'Seleccioná una empresa',
          requiredCheck: 'Marcá las empresas que pertenecen al grupo',
          requiredRadio: 'Seleccioná la respuesta',
          atLeast1: 'Marcá al menos uno',
        },
      },
    },
    domainErp: {
      albor: 'Albor',
      finnegans: 'Finnegans',
    },
  };
  