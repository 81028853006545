import React, { useState } from "react";
import { Box, Link } from "@mui/material";
import { Paragraph } from "components/Typography";
import UseConditons from "components/modals/useConditions";
import { colors } from "utils/colors";

export default function Footer() {
  const [open, setOpen] = useState(false);

  const year = new Date().getFullYear();

  const onClose = () => setOpen(false);
  return (
    <Box style={{ display: "flex", alignItems: "center", padding: "24px" }}>
      <Paragraph preset="P4" color={colors.neutral.black50}>
        &copy; Copyright {year}
      </Paragraph>
      <Link
        component="button"
        underline="always"
        color={colors.neutral.black70}
        sx={{ marginLeft: "16px" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <Paragraph preset="P4"> Condiciones de uso</Paragraph>
      </Link>
      <UseConditons open={open} close={onClose} />
    </Box>
  );
}
