import React, { useRef, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import toast from "components/Toast/index.jsx";
import { Paragraph, Title } from "components/Typography";
import api from "services/api";
import { colors } from "utils/colors";
import { useLocation } from "react-router";
import { observer } from "mobx-react-lite";
import { frameOptions, contentOptions } from "utils/utilities";
import { Item } from "models/nav";
import { useStores } from "store/root-store/root-store-context";
import { toJS } from "mobx";

const Dashboards: React.FC = () => {
  const { navStore } = useStores();
  const location = useLocation();
  const [item, setItem] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [dashboardReady, setDashboardReady] = useState(false);
  const [urlDashboard, setUrlDashboard] = useState<string>("");

  const { pathname } = location;
  const dashboardRef = useRef<HTMLDivElement | null>(null);

  const loadDashboard = async (url: string) => {
    setLoading(true);
    // const embeddingContext = await createEmbeddingContext();
    const embeddingContext = await createEmbeddingContext({
      onChange: (changeEvent) => {
        if (changeEvent.message === "The frame loaded") {
          setLoading(false);
        }
      },
    });
    const { embedDashboard } = embeddingContext;
    if (url) {
      dashboardRef.current?.replaceChildren();
      const framesOptions = frameOptions(urlDashboard, dashboardRef);
      try {
        embedDashboard(framesOptions, contentOptions);
        setDashboardReady(true);
      } catch (error) {
        toast.unhandledError("error de dashboard");
        throw new Error(
          "Estamos teniendo inconvenientes para realizar la acción solicitada, reintentá más tarde."
        );
      }
    }
  };

  const handleDataDashboard = async (idTablero: number) => {
    const response = await api.community.getDashboard(
      // state.id || state[0].items[0].tablero.id
      idTablero
    );

    if (response.id) {
      setUrlDashboard(response.embedUrl);
      setDashboardReady(false);
    }
  };

  // TODO: Mejorar esta parte para que no se haga doble llamado
  // en el primer render debido a los dos siguientes useEffect
  useEffect(() => {
    const target = toJS(navStore.itemByUrl(pathname));
    if (target) {
      setItem(target as Item | null);
      handleDataDashboard(target.tablero?.id || 0);
    } else {
      setItem({tablero: location.state})
      handleDataDashboard(location.state?.id)
    }
  }, [pathname]);

  useEffect(() => {
    const target = toJS(navStore.itemByUrl(pathname));
    if (target) {
      setItem(target as Item | null);
      handleDataDashboard(target.tablero?.id || 0);
    } else {
      setItem({tablero: location.state})
      handleDataDashboard(location.state?.id)
    }
  }, []);

  useEffect(() => {
    if (dashboardRef.current && !dashboardReady && urlDashboard) {
      loadDashboard(urlDashboard);
    }
  }, [dashboardRef.current, urlDashboard]);

  return (
    <Box display="flex" flexDirection="column" key={item?.id}>
      <Box bgcolor={colors.neutral.black30} padding="16px 24px 24px">
        <Box marginBottom="8px">
          <Title preset="T1" color={colors.primary.darkGreen}>
            {item?.tablero?.titulo || "Titulo"}
          </Title>
        </Box>
        <Paragraph preset="P2">
          {/* subtitulo */}
          {item?.tablero?.subtitulo || "Subtitulo"}
        </Paragraph>
      </Box>
      {loading && (
        <Box
          style={{
            height: "calc(100vh - 112px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title preset="T4">Cargando...</Title>
        </Box>
      )}
      <Box
        width="97%"
        // height="100vh"
        // key={state.id}

        alignSelf="center"
        ref={dashboardRef}
        display="flex"
        padding="16px"
        flexDirection="column"
        justifyContent="flex-start"
        bgcolor="white"
      />
    </Box>
  );
};
export default observer(Dashboards);
