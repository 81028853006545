import React from "react";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { colors } from "utils/colors";

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.neutral.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.neutral.black,
  },
}));
