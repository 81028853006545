import React, { FC } from "react"
import { Box } from "@mui/material"
import CheckSvg from "../../../assets/icons/check.svg"
import { colors } from "utils/colors"
import { Paragraph } from "components/Typography"

type TCheckListPass = {
  label: string;
  checked: boolean;
  value: number;
}

interface ICheckListPass {
  checksList: TCheckListPass[];
}


export const ChekcListPass: FC<ICheckListPass> = ({ checksList }) => {

  return (
    <Box marginTop='12px' marginBottom='12px'>
      <Box style={{ margin: '16px 0px 0px 0px', display: 'flex', justifyContent: 'center' }}>
        <Paragraph preset="P3">Tu contraseña debe incluir al menos:</Paragraph>
      </Box>
      {
        checksList.map(e => {
          return (
            <Box
              key={e.label}
              marginLeft={e.checked ? '5px' : '8px'}
              display='flex'
              justifyContent='flex-start'
              flexDirection='row'
              alignContent='center'
              alignItems='center'
              textAlign='center'
              marginTop='4px'
            >
              <Paragraph preset="P4" color={colors.neutral.black50}>
                {e.checked ? <img width='11px' src={CheckSvg} alt='check' /> : '.'}
              </Paragraph>
              <Box
              style={{
                marginLeft: e.checked ? '12px' : '17px',
                marginTop: e.checked ? '4px' : '4px'
              }}
              >
                <Paragraph fontWeight={e.checked ? "bold" : "400"} color={e.checked ? colors.primary.lightGreen : colors.neutral.black50} preset="P4">{e.label}</Paragraph>
              </Box>
            </Box>
          )
        })
      }
    </Box>
  )
}