import { useState, useEffect } from "react";

export default function useWindowDimensions(query: string) {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => {
      setMatches(media.matches);
    };

    media.addEventListener("resize", listener);
    return () => media.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
}
