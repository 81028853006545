import { Modal, Box, Card } from "@mui/material";
import { Paragraph, Title } from "components/Typography";
import React from "react";
import { colors } from "utils/colors";

interface UseConditionsProps {
  open: boolean;
  close: () => void;
}

export default function UseConditons({ open, close }: UseConditionsProps) {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      aria-describedby="modal-modal-description"
    >
      <Card sx={{ width: "560px", padding: "40px" }}>
        <Title preset="T3" color={colors.primary.darkGreen}>
          Condiciones de uso
        </Title>
        <Box style={{ marginTop: "24px" }}>
          <Paragraph preset="P2">
            Toda la información suministrada por el presente servicio no deberá
            ser comercializada, reproducida, publicada, ni cedida; total ni
            parcialmente, sin expresa autorización.
          </Paragraph>
          <Paragraph preset="P2">
            Los Reportes agroview no constituyen ninguna recomendación; sino que
            brindan información actualizada que se ha creado, obtenido y
            procesado dando cumplimiento a las normas de protección de datos
            personales y demás normativas aplicables, para que el cliente
            disponga de herramientas adicionales para sus propias decisiones
            vinculadas a sus actividades de agricultura, ganadería, lechera,
            forestal, agrícola intensiva de cualquier tipo y/o actividades
            afines.
          </Paragraph>
        </Box>
      </Card>
    </Modal>
  );
}
