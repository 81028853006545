export const colors = {
  primary: {
    darkGreen: "#00823E",
    lightGreen: "#5FB242",
  },
  secondary: {
    urobilin: "#E5AB13",
    yellow: "#F2CF00",
    acidGreen: "#BCCC11",
    appleGreen: "#86AC10",
    lightSeaGreen: "#18C2A3",
    blueGreen: "#1E98AE",
    lapizLazuli: "#2469A8",
    yaleBlue: "#184F8E",
  },
  neutral: {
    black: "#1A1A1A",
    black70: "#4F4C4A",
    black50: "#A09991",
    black30: "#F5F1ED",
    white: "#FFFFFF",
  },
  error: "#F63C14",
  overlay: "rgba(52, 57, 54, 0.6)",
};
