import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export interface ApiListResponse<T> {
  data: T[];
}

export class AuthApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getToken(
    username: string,
    password: string,
    token?: string,
  ): Promise<any> {
    try {
      const response: ApiResponse<{ token: string }> =
        await this.api.apisauce.post('/token_auth/', {
          username,
          password,
          'g-recaptcha-response': token,
        });

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return { ...problem, status: response.status };
      }

      const { data } = response;
      if (data) {
        this.api.apisauce.setHeaders({
          Authorization: `Token ${data.token}`,
        });
        return { token: data.token };
      }
      return { token: '' };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async logout(): Promise<any> {
    try {
      const response: ApiResponse<{ token: string }> =
        await this.api.apisauce.post('/token_deauth/');

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      this.api.apisauce.deleteHeader('Authorization');

      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
