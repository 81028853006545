import React from "react";
import { Box } from "@mui/material";
import { Paragraph, Title } from "components/Typography";
import IniciarSesionForm from "components/Forms/iniciarSesion";

const Login: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Box mb="24px">
        <Title preset="T2">Bienvenido</Title>
        <Paragraph preset="P1">
          Ingresá las credenciales de tu cuenta para acceder a la plataforma.
        </Paragraph>
      </Box>
      <IniciarSesionForm />
    </Box>
  );
};

export default Login;
