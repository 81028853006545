import styled from "styled-components";
import { Card } from "@mui/material";
import { pxToRem } from "utils/metrics";

export const StyledContainer = styled.div<{ img: string; height?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.img});
  background-size: cover;
  height: ${(props) => props.height || "100vh"};
  width: 100%;
  overflow: auto;
`;

export const StyledContent = styled(Card)`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  max-width: ${pxToRem(532 - 80)}; // 532px - 80px (padding)
  width: 100%;
  padding: ${pxToRem(40)};
  /* position: absolute;
  top: 82px;
  left: 30%; */
`;
