import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Paragraph, Title } from "components/Typography";
import { colors } from "utils/colors";
import RecuperarVerificarForm from "components/Forms/recuperarVerificar";

const RecuperarVerificar: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column">
      <Box
        onClick={() => navigate("/portal/iniciar-sesion")}
        sx={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "125px",
        }}
      >
        <ArrowBack
          fontSize="small"
          sx={{ color: colors.primary.lightGreen, mr: "6px", mt: "3px" }}
        />
        <Title preset="T6" color={colors.primary.lightGreen}>
          Iniciar sesión
        </Title>
      </Box>
      <Box mb="24px">
        <Title preset="T2">Generar contraseña</Title>
        <Paragraph preset="P1">
          Creá una nueva contraseña para acceder a tu cuenta.
        </Paragraph>
      </Box>
      <RecuperarVerificarForm />
    </Box>
  );
};

export default RecuperarVerificar;
