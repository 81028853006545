import { CSSObject, styled } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { pxToRem } from "utils/metrics";

const drawerWidth = pxToRem(213);

const openedMixin: CSSObject = {
  maxWidth: drawerWidth,
  width: "100%",
  transition: "ease 150ms",
  overflowX: "hidden",
};

const closedMixin: CSSObject = {
  ...openedMixin,
  maxWidth: pxToRem(63),
};

export const DrawerNav = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin,
    "& .MuiDrawer-paper": openedMixin,
  }),
  ...(!open && {
    ...closedMixin,
    "& .MuiDrawer-paper": closedMixin,
  }),
}));

export const DrawerHeaderNav = styled("div")(({ open }: { open: boolean }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: open ? "space-between" : "center",
  padding: pxToRem(16),
  paddingBottom: pxToRem(11),
}));
