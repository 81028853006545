import { types, Instance } from "mobx-state-tree";

export const Tablero = types.model("Tablero").props({
  id: types.number,
  titulo: types.string,
  subtitulo: types.string,
});
type TableroModelType = Instance<typeof Tablero>;
export type TableroType = TableroModelType;

export const Item = types.model("Item").props({
  id: types.number,
  nombre: types.string,
  logo: types.string,
  urlFront: types.string,
  tablero: types.maybeNull(Tablero),
  htmlTag: types.maybeNull(types.string),
});
type ItemModelType = Instance<typeof Item>;
export type ItemType = ItemModelType;

export const Seccion = types.model("Seccion").props({
  seccion: types.string,
  logo: types.string,
  items: types.array(Item),
});
type SeccionModelType = Instance<typeof Seccion>;
export type SeccionType = SeccionModelType;
