import React from "react";
import { Box } from "@mui/material";
import { Paragraph, Title } from "components/Typography";
import { colors } from "utils/colors";
import AgroviewLogo from "assets/agroview_logo.svg";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import useWindowDimensions from "hooks/useWindowsSize";

interface IUserIsInMobile {
  children: React.ReactNode;
}

export default function UserIsInMobile({ children }: IUserIsInMobile) {
  const isMobile = useWindowDimensions("(max-width: 767px)");

  if (!isMobile) {
    return <>{children}</>;
  }

  return (
    <Box
      sx={{
        width: "calc(100% - 24px)",
        minHeight: "calc(100vh - 24px)",
        padding: "12px",
        display: "flex",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        bgcolor: colors.neutral.black30,
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          maxWidth: "450px",
          padding: "40px",
          borderRadius: "8px",
        }}
      >
        <WarningAmberIcon sx={{ fontSize: "44px" }} />
        <Title preset="T3">Visitanos en la compu</Title>
        <Box sx={{ marginTop: "10px" }}>
          <Paragraph preset="P1">
            Nuestra plataforma está optimizada para utilizarla en monitores de
            mayor resolución
          </Paragraph>
        </Box>
      </Box>
      <Box className="logo" width="auto">
        <img
          style={{ color: "#FFFFFF", margin: "40px" }}
          width="177px"
          height="35px"
          src={AgroviewLogo}
          alt="agroview-logo"
        />
      </Box>
    </Box>
  );
}
