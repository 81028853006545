/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useStores } from "store/root-store/root-store-context";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Title } from "components/Typography";
import { colors } from "utils/colors";
import { Paragraph } from "components/Typography";
import { rootStore } from "store/root-store/root-store";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AlertTknExpired = observer(() => {
  const { sessionStore } = useStores();
  const isOpenModal = Boolean(sessionStore.showModal);
  const navigation = useNavigate();

  const handleClose = () => {
    rootStore.resetStore();
    sessionStore.removeReasonTokenExpired();
    navigation("/portal/iniciar-sesion", { replace: true });
  };

  return (
    <div>
      <Dialog
        open={isOpenModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          maxWidth: "100%",
          "& .MuiDialog-paper": {
            maxWidth: "380px",
          },
          ".MuiPaper-root": {
            padding: "40px",
          },
        }}
      >
        <DialogTitle sx={{ p: "0", mb: "24px" }}>
          <Title preset="T3" color={colors.primary.darkGreen}>
            {sessionStore.reason}
          </Title>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Paragraph preset="P1">{sessionStore.message}</Paragraph>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 0, mt: "8px" }}>
          <Button variant="success" color="primary" onClick={handleClose}>
            Volver a iniciar sesión
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
