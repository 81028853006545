import React, { useEffect } from "react";
import { Box } from "@mui/material";
import ComunidadImg from "assets/comunidad-campos.png";
import { StyledContainer, StyledContent } from "./index.styled";
import { LogoPrincipal } from "components/Icons/logoPrincipal";
import { useNavigate } from "react-router-dom";
import { useStores } from "store/root-store/root-store-context";

const PortalLayout: React.FC<{ children: React.ReactNode }> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const { sessionStore, navStore } = useStores();
  useEffect(() => {
    if (sessionStore.token !== null && navStore.secciones[0].items !== null) {
      navigate(`${navStore.secciones[0].items[0].urlFront}`, {replace: true});
    }
  }, []);

  return (
    <StyledContainer img={ComunidadImg}>
      <Box position="absolute" top="40px" left="40px">
        <LogoPrincipal color="#FFFFFF" width={177} height={35} />
      </Box>
      <StyledContent>{children}</StyledContent>
    </StyledContainer>
  );
};

export default PortalLayout;
