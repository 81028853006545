import React, { Suspense, useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";
import persist from "mst-persist";
import { observer } from "mobx-react";
import { ThemeProvider } from "@mui/material";
import TagManager from "react-gtm-module";
import { SnackbarProvider } from "notistack";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";
import Portal from "screen/portal";
import Main from "screen/main";
import rollbarConfig from "utils/rollbar-config";
import ErrorBoundaryUI from "components/Layouts/ErrorBoundary";
import { theme } from "utils/muiThemeConfig";
import { rootStore } from "store/root-store/root-store";
import api from "services/api";
import { RootStoreProvider } from "store/root-store/root-store-context";
import "./App.css";
import UserIsInMobile from "components/Layouts/UserIsInMobile";

const RenderContent: React.FC = () => {
  return (
    <UserIsInMobile>
      <Routes>
        <Route path="/portal/*" element={<Portal />} />
        <Route path="/*" element={<Main />} />
      </Routes>
    </UserIsInMobile>
  );
};

const App: React.FC = () => {
  const [appReady, setAppReady] = useState(false);

  persist("agroview", rootStore).then(() => {
    if (rootStore.sessionStore.token && rootStore.sessionStore.token !== "") {
      api.api.setToken(rootStore.sessionStore.token);
    }
    setAppReady(true);
  });

  const tagManagerArgs = {
    gtmId: "$ENV_GTAG",
    //gtmId: import.meta.env.VITE_ENVIRONMENT === 'production' ? import.meta.env.ENV_GTAG : window.config.gtag,
  };

  useMemo(() => TagManager.initialize(tagManagerArgs), []);

  if (!appReady) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <RollbarProvider config={rollbarConfig}>
        <ErrorBoundary
          fallbackUI={ErrorBoundaryUI as unknown as React.ReactNode}
        >
          <SnackbarProvider maxSnack={3}>
            <RootStoreProvider value={rootStore}>
              <Suspense>
                <RenderContent />
              </Suspense>
            </RootStoreProvider>
          </SnackbarProvider>
          <div id="snackbarhelper" />
        </ErrorBoundary>
      </RollbarProvider>
    </ThemeProvider>
  );
};

export default observer(App);
