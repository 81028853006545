/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { List } from "@mui/material";
import { observer } from "mobx-react";
import { Seccion } from "models/nav";
import NavDivisor from "../NavDivisor";
import { useStores } from "store/root-store/root-store-context";

interface INavList {
  open: boolean;
}

const NavList: React.FC<INavList> = observer(({ open }) => {
  const { navStore } = useStores();
  const secciones: Seccion[] = navStore.secciones;

  return (
    <List>
      {secciones &&
        secciones.map(
          (navSeccion: Seccion) =>
            navSeccion.items && (
              <NavDivisor
                key={navSeccion.seccion}
                open={open}
                navSeccion={navSeccion}
              />
            )
        )}
    </List>
  );
});

export default NavList;
