declare global {
    interface Window {
      config: Record<string, string>;
    }
  }
  
  //cambiar por url de agroview
  const API_URL = import.meta.env.VITE_API_BASE_URL || 'https://dev.api.agroview.com.ar';
  
  /**
   * The options used to configure the API.
   */
  export interface ApiConfig {
    /**
     * The URL of the api.
     */
    url: string;
  
    /**
     * Milliseconds before we timeout the request.
     */
    timeout: number;
  }
  
  /**
   * The default configuration for the app.
   */
  export const DEFAULT_API_CONFIG: ApiConfig = {
    url: API_URL,
    timeout: 100000,
  };
  