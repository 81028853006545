import React, { useState } from "react";
import { Collapse } from "@mui/material";
import { observer } from "mobx-react";
import { Seccion } from "models/nav";
import NavItemDivisor from "../NavItemDivisor";
import NavItem from "../NavItem";

interface INavDivisor {
  open: boolean;
  navSeccion: Seccion;
}

const NavDivisor: React.FC<INavDivisor> = observer(({ open, navSeccion }) => {
  const [isCollapse, setCollapse] = useState(true);
  return (
    <>
      {open && (
        <NavItemDivisor
          open={open}
          icon={navSeccion.logo}
          title={navSeccion.seccion}
          collapsed={isCollapse}
          handleClick={() => setCollapse(!isCollapse)}
        />
      )}
      <Collapse in={isCollapse || !open} unmountOnExit>
        {navSeccion.items &&
          navSeccion.items.map((navItem) => (
            <NavItem
              key={navItem.id}
              open={open}
              item={{ ...navItem, logo: navSeccion.logo }}
            />
          ))}
      </Collapse>
    </>
  );
});

export default NavDivisor;
