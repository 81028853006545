/* eslint-disable no-param-reassign */
import { types, Instance, SnapshotOut } from "mobx-state-tree";
import { UserData, UserDataType } from "./userModel";
import api from "services/api";

export const SessionModel = types
  .model("Session")
  .props({
    currentUser: types.maybe(UserDataType),
    token: types.maybeNull(types.string),
    showModal: types.maybeNull(types.boolean),
    reason: types.maybeNull(types.string),
    message: types.maybeNull(types.string),
    isLogged: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get fullName() {
      return `${self.currentUser?.firstName} ${self.currentUser?.lastName}`;
    },
    get isUserAdmin() {
      if (!self.currentUser) {
        return false;
      }
      return true;
    },

    get reasonTokenExpired() {
      return self.showModal ? self.message : null;
    },
  }))
  .actions((self) => {
    function setToken(token: string | null) {
      self.isLogged = true;
      self.token = token;
    }

    function setCurrentUserOffline(user: UserData | undefined) {
      self.currentUser = user;
    }

    function logOut() {
      self.isLogged = null;
      setCurrentUserOffline(undefined);
      setToken(null);
    }

    function setReasonTokenExpired(
      showModal: boolean,
      reason: string,
      message: string
    ) {
      self.showModal = showModal;
      self.reason = reason;
      self.message = message;
    }

    function removeReasonTokenExpired() {
      self.showModal = null;
      self.message = null;
      self.isLogged = null;
      setCurrentUserOffline(undefined);
      setToken(null);
    }

    return {
      setToken,
      setCurrentUserOffline,
      logOut,
      setReasonTokenExpired,
      removeReasonTokenExpired,
    };
  })
  .actions((self) => ({
    getMeUser: async () => {
      const request = await api.me.getMeInfo();
      if (!request.kind) {
        self.setCurrentUserOffline(request.data);
      }
    },
  }))
  .actions((self) => ({
    login: async (username: string, password: string) => {
      const response = await api.auth.getToken(username, password);
      api.api.setToken(response.token);
      self.setToken(response.token);
      self.getMeUser();
    },
  }));

type SessionModelType = Instance<typeof SessionModel>;
export type Session = SessionModelType;
type SessionSnapshotType = SnapshotOut<typeof SessionModel>;
export type SessionSnapshot = SessionSnapshotType;
export const createSessionDefaultModel = () => types.optional(SessionModel, {});
