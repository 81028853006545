import React, { useState } from "react";
import { Box, TextField, Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RecoverSchema } from "utils/validations.schema";
import api from "services/api";
import { Strings } from "constants/strings";
import { useSnackbar } from "notistack";

type PayloadGenerar = {
  oldPassword: string;
  newPassword: string;
};

const RecuperarContrasenaForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RecoverSchema),
    defaultValues: { oldPassword: "", newPassword: "" },
  });
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);


  const onSubmit = async (data: PayloadGenerar) => {
    setIsSubmitting(true);
    const response = await api.passwords.changePassword(data);
    if (response.kind === "token-not-found-or-invalid") {
      enqueueSnackbar(Strings.notifications.tokenNotFoundOrInvalid, {
        variant: "error",
      });
    }
    if (response.status && response.status === "password_reseted") {
      enqueueSnackbar(Strings.notifications.success, {
        variant: "success",
      });
      navigate("/portal/iniciar-sesion");
    }
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" gap="16px" mb="16px">
        <TextField
          id="password"
          label="Contraseña"
          type="password"
          error={Boolean(errors.oldPassword)}
          helperText={errors.oldPassword?.message}
          {...register("oldPassword")}
        />
        <TextField
          id="newPassword"
          label="Contraseña"
          type="password"
          error={Boolean(errors.newPassword)}
          helperText={errors.newPassword?.message}
          {...register("newPassword")}
        />
      </Box>
      <Button disabled={isSubmitting} type="submit" variant="success" color="primary">
        {
          isSubmitting ? (
            <Box sx={{ marginRight: '5px', width: '60px' }}>
              <CircularProgress size='25px' color="inherit" />
            </Box>
          )
          :
          'Ingresar'
        }
      </Button>
    </form>
  );
};

export default RecuperarContrasenaForm;
