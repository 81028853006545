/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import api from "services/api";
import { Strings } from "constants/strings";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TooltipPassword } from "components/commons/tooltipPassword";
import { ChekcListPass } from "components/commons/checkList";
import { capitalLetter, checks, lessEigthChar, lowercaseLetter, pipe, validationsRules } from "utils/utilities";

type PayloadRecupero = {
  password: string;
  repeatNewPassword: string;
};

const RecuperarVerificarForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("codigo");
  const [openPopUp, setOpenPopUp] = useState(false);
  const [valuePass, setValuePass] = useState<string>('');
  const [checkListArr, setCheckListArr] = useState<any[]>(checks);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    //resolver: yupResolver(NewPasswordValidationSchema),
    defaultValues: {
      password: "",
      repeatNewPassword: "",
    },
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    repeatNewPassword: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClickShowPassword = (value: "password" | "repeatNewPassword") => {
    setShowPassword((state) => {
      return { ...state, [value]: !state[value] };
    });
  };

  const onSubmit = async (data: PayloadRecupero) => {
    setIsSubmitting(true);
    const { password, repeatNewPassword } = data;
    if (password === repeatNewPassword && token) {
      const body = {
        password,
        token,
      };
      const response = await api.passwords.newPassword(body);
      if (response.status && response.status === "password_reseted") {
        enqueueSnackbar(Strings.notifications.success, {
          variant: "success",
        });
        navigate("/portal/iniciar-sesion");
      } else {
        enqueueSnackbar(Strings.notifications.commonError, {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar(Strings.notifications.commonError, {
        variant: "error",
      });
    }
    setIsSubmitting(false);
  };

  const handleFocus = () => {
    setOpenPopUp(true);
  };
  const handleBlur = () => {
    setOpenPopUp(false);
  };

  const handleMatch = () => {
    const iterable = pipe(
      lowercaseLetter,
      capitalLetter,
      lessEigthChar,
    )(valuePass);

    const checksListMatched = checks.map((e) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const match = iterable.forEach((v: any) => {
        if (v.key === e.key) return (e.checked = v.checked);
      });
      return { ...e };
    });
    return checksListMatched;
  };

  const handleChange = (v: string) => {
    setValuePass(v);
  }

  useEffect(() => {
    setCheckListArr(handleMatch());
  }, [valuePass]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" gap="16px" mb="16px">
      <Controller 
          control={control}
          name="password"
          defaultValue=""
          rules={validationsRules}
          render={({ field: { value, onChange }}) => (
            handleChange(value),
            <TooltipPassword
                open={openPopUp}
                onClose={() => setOpenPopUp(false)}
                onOpen={() => setOpenPopUp(true)}
                title={<ChekcListPass checksList={checkListArr} />}
                placement='left-start'
                arrow
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <TextField
                  onFocus={handleFocus}
                  id="password"
                  sx={{ mb: "20px" }}
                  label="Nueva contraseña"
                  placeholder="Nueva contraseña"
                  type={showPassword.password ? "text" : "password"}
                  error={Boolean(errors.password)}
                  helperText={errors.password ? String(errors.password?.message) : ""}
                  {...register("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword("password")}
                          edge="end"
                        >
                          {showPassword.password ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    onBlur: () => handleBlur(),
                    onChange
                  }}
                />
              </TooltipPassword>
          )}
        />
        <TextField
          id="repeatNewPassword"
          sx={{ mb: "20px" }}
          label="Repetir contraseña"
          placeholder="Repetir contraseña"
          type={showPassword.repeatNewPassword ? "text" : "password"}
          error={Boolean(errors.repeatNewPassword)}
          helperText={
            errors.repeatNewPassword
              ? String(errors.repeatNewPassword?.message)
              : ""
          }
          {...register("repeatNewPassword", { 
            required: {value: true, message: 'Este campo es requerido'}, 
            validate: (value) => valuePass === value || 'Las contraseñas no coinciden'
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("repeatNewPassword")}
                  edge="end"
                >
                  {showPassword.repeatNewPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Button disabled={isSubmitting} type="submit" variant="success" color="primary">
      {
        isSubmitting ? (
          <Box sx={{ marginRight: '5px', width: '60px' }}>
            <CircularProgress size='25px' color="inherit" />
          </Box>
        )
        :
        'Ingresar'
      }
      </Button>
    </form>
  );
};

export default RecuperarVerificarForm;
