import { Interpolation, Theme, createTheme } from "@mui/material";
import { colors } from "./colors";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobileS: true;
    mobileM: true;
    mobileL: true;
    tabletS: true;
    tablet: true;
    laptop: true;
    laptopL: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    success: true;
    critic: true;
    disabled: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsVariantOverrides {
    success: true;
  }
}

const fontButton: Interpolation<{ theme: Theme }> = {
  fontFamily: "Exo2",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "20px",
  textTransform: "none",
};

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobileS: 320,
      mobileM: 375,
      mobileL: 425,
      tabletS: 500,
      tablet: 768,
      laptop: 1024,
      laptopL: 1440,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "125%",
          border: `0px solid ${colors.neutral.black50} !important`,
          color: `${colors.neutral.black70} !important`,
          "&.Mui-focused": {
            border: "none !important",
            color: `${colors.neutral.black} !important`,
          },
          "&.Mui-focused > fieldset": {
            border: `2px solid ${colors.primary.lightGreen} !important`,
          },
          "&.Mui-error > fieldset": {
            border: `1px solid ${colors.error} !important`,
          },
          "&.Mui-error.Mui-focused > fieldset": {
            border: `2px solid ${colors.error} !important`,
          },
          "&.Mui-disabled > fieldset": {
            border: `1px solid ${colors.neutral.black30} !important`,
          },
          "&.Mui-disabled.Mui-focused > fieldset": {
            border: `1px solid ${colors.neutral.black30} !important`,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "125%",
          color: `${colors.neutral.black50} !important`,
          "&.Mui-focused": {
            color: `${colors.neutral.black70} !important`,
          },
          "&.MuiInputLabel-shrink:not(.Mui-focused)": {
            "& ~ .MuiOutlinedInput-root": {
              border: "none !important",
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "125%",
          marginLeft: "0",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-sizeLarge": {
            height: "40px !important",
          },
          "&.MuiButton-sizeMedium": {
            height: "40px !important", // No implementado en el diseño, cambiar cuando se implemente
          },
          "&.MuiButton-sizeSmall": {
            height: "32px !important", // No implementado en el diseño, cambiar cuando se implemente
          },
        },
      },
      variants: [
        {
          props: { variant: "success", color: "primary" },
          style: {
            ...fontButton,
            border: `1px solid ${colors.primary.lightGreen}`,
            backgroundColor: colors.primary.lightGreen,
            color: colors.neutral.white,
            "&:hover": {
              backgroundColor: colors.primary.lightGreen,
            },
          },
        },
        {
          props: { variant: "success", color: "primary", disabled: true },
          style: {
            ...fontButton,
            border: `1px solid ${colors.neutral.black30}`,
            backgroundColor: colors.neutral.black30,
            color: colors.neutral.black50,
            "&:hover": {
              backgroundColor: colors.neutral.black30,
            },
          },
        },
        {
          props: { variant: "success", color: "secondary" },
          style: {
            ...fontButton,
            border: `1px solid ${colors.primary.lightGreen}`,
            backgroundColor: "transparent",
            color: colors.primary.lightGreen,
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { variant: "success", color: "secondary", disabled: true },
          style: {
            ...fontButton,
            border: `1px solid ${colors.neutral.black30}`,
            backgroundColor: colors.neutral.black30,
            color: colors.neutral.black50,
            "&:hover": {
              backgroundColor: colors.neutral.black30,
            },
          },
        },
        {
          props: { variant: "critic", color: "primary" },
          style: {
            ...fontButton,
            border: `1px solid ${colors.error}`,
            backgroundColor: colors.error,
            color: colors.neutral.white,
            "&:hover": {
              backgroundColor: colors.error,
            },
          },
        },
        {
          props: { variant: "critic", color: "primary", disabled: true },
          style: {
            ...fontButton,
            border: `1px solid ${colors.neutral.black30}`,
            backgroundColor: colors.neutral.black30,
            color: colors.neutral.black50,
            "&:hover": {
              backgroundColor: colors.neutral.black30,
            },
          },
        },
        {
          props: { variant: "critic", color: "secondary" },
          style: {
            ...fontButton,
            border: `1px solid ${colors.error}`,
            backgroundColor: "transparent",
            color: colors.error,
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { variant: "critic", color: "secondary", disabled: true },
          style: {
            ...fontButton,
            border: `1px solid ${colors.neutral.black30}`,
            backgroundColor: colors.neutral.black30,
            color: colors.neutral.black50,
            "&:hover": {
              backgroundColor: colors.neutral.black30,
            },
          },
        },
        {
          props: { variant: "disabled", color: "primary" },
          style: {
            ...fontButton,
            border: `1px solid ${colors.neutral.black30}`,
            backgroundColor: colors.neutral.black30,
            color: colors.neutral.black50,
          },
        },
        {
          props: { variant: "disabled", color: "secondary" },
          style: {
            ...fontButton,
            border: `1px solid ${colors.neutral.black30}`,
            backgroundColor: colors.neutral.black30,
            color: colors.neutral.black50,
          },
        },
      ],
    },
  },
});
