import React from "react";
import { StyledTooltip } from "./index.styled";

interface TooltipProps {
  title: string;
  placement?: "bottom" | "left" | "right" | "top";
  children: React.ReactElement;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  placement = "right",
  children,
}) => {
  return (
    <StyledTooltip title={title} placement={placement} arrow>
      {children}
    </StyledTooltip>
  );
};

export default Tooltip;
