import React from "react";
import { colors } from "utils/colors";
import { pxToRem } from "utils/metrics";

type fontWeights =
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900"
  | "bold"

interface ITitle {
  preset: "T1" | "T2" | "T3" | "T4" | "T5" | "T6" | "Caps";
  color?: string;
  fontWeight?: fontWeights;
  children: React.ReactNode;
}

interface IParagraph {
  preset: "P1" | "P2" | "P3" | "P4";
  color?: string;
  fontWeight?: fontWeights;
  children: React.ReactNode;
}

const stylesHeading = {
  T1: {
    fontStyle: "normal",
    fontSize: pxToRem(40),
    lineHeight: pxToRem(44),
    fontFamily: "Exo2",
  },
  T2: {
    fontStyle: "normal",
    fontSize: pxToRem(36),
    lineHeight: "110%",
    fontFamily: "Exo2",
  },
  T3: {
    fontStyle: "normal",
    fontSize: pxToRem(28),
    lineHeight: "110%",
    fontFamily: "Exo2",
  },
  T4: {
    fontStyle: "normal",
    fontSize: pxToRem(24),
    lineHeight: "110%",
    fontFamily: "Exo2",
  },
  T5: {
    fontStyle: "normal",
    fontSize: pxToRem(20),
    lineHeight: "110%",
    fontFamily: "Exo2",
  },
  T6: {
    fontStyle: "normal",
    fontSize: pxToRem(16),
    lineHeight: "110%",
    fontFamily: "Exo2",
  },
  Caps: {
    fontStyle: "normal",
    fontSize: pxToRem(12),
    lineHeight: "110%",
    fontFamily: "Exo2",
  },
};

const stylesParagraph = {
  P1: {
    fontStyle: "normal",
    fontSize: pxToRem(18),
    lineHeight: "125%",
    fontFamily: "Inter",
  },
  P2: {
    fontStyle: "normal",
    fontSize: pxToRem(16),
    lineHeight: "125%",
    fontFamily: "Inter",
  },
  P3: {
    fontStyle: "normal",
    fontSize: pxToRem(14),
    lineHeight: "125%",
    fontFamily: "Inter",
  },
  P4: {
    fontStyle: "normal",
    fontSize: pxToRem(12),
    lineHeight: "125%",
    fontFamily: "Inter",
  },
};

export const Title: React.FC<ITitle> = ({
  preset,
  color = colors.neutral.black,
  fontWeight = "600",
  children,
}) => {
  switch (preset) {
    case "T1":
      return (
        <h1
          style={{
            ...stylesHeading.T1,
            color,
            fontWeight,
          }}
        >
          {children}
        </h1>
      );
    case "T2":
      return (
        <h2
          style={{
            ...stylesHeading.T2,
            color,
            fontWeight,
          }}
        >
          {children}
        </h2>
      );
    case "T3":
      return (
        <h3
          style={{
            ...stylesHeading.T3,
            color,
            fontWeight,
          }}
        >
          {children}
        </h3>
      );
    case "T4":
      return (
        <h4
          style={{
            ...stylesHeading.T4,
            color,
            fontWeight,
          }}
        >
          {children}
        </h4>
      );
    case "T5":
      return (
        <h5
          style={{
            ...stylesHeading.T5,
            color,
            fontWeight,
          }}
        >
          {children}
        </h5>
      );
    case "T6":
      return (
        <h6
          style={{
            ...stylesHeading.T6,
            color,
            fontWeight,
          }}
        >
          {children}
        </h6>
      );
    case "Caps":
      return (
        <p
          style={{
            ...stylesHeading.Caps,
            color,
            fontWeight,
          }}
        >
          {children}
        </p>
      );
    default:
      throw new Error("No se ha definido el preset");
  }
};

export const Paragraph: React.FC<IParagraph> = ({
  preset,
  color = colors.neutral.black,
  fontWeight = "400",
  children,
}) => {
  switch (preset) {
    case "P1":
      return (
        <p
          style={{
            ...stylesParagraph.P1,
            color,
            fontWeight,
          }}
        >
          {children}
        </p>
      );
    case "P2":
      return (
        <p
          style={{
            ...stylesParagraph.P2,
            color,
            fontWeight,
          }}
        >
          {children}
        </p>
      );
    case "P3":
      return (
        <p
          style={{
            ...stylesParagraph.P3,
            color,
            fontWeight,
          }}
        >
          {children}
        </p>
      );
    case "P4":
      return (
        <p
          style={{
            ...stylesParagraph.P4,
            color,
            fontWeight,
          }}
        >
          {children}
        </p>
      );
    default:
      throw new Error("No se ha definido el preset");
  }
};
