/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useStores } from "store/root-store/root-store-context";
import { useSnackbar } from "notistack";
import api from "services/api";
import { Strings } from "constants/strings";
import { TooltipPassword } from "components/commons/tooltipPassword";
import { ChekcListPass } from "components/commons/checkList";
import { capitalLetter, checks, lessEigthChar, lowercaseLetter, pipe, specialChar, validationsRules } from "utils/utilities";

type PayloadCuenta = {
  firstname: string;
  lastname: string;
  password: string;
  confirmPassword: string;
};

interface IActivarCuentaProps {
  token: any;
  emailInvitation: string;
}

const ActivarCuentaForm = ({ token, emailInvitation }: IActivarCuentaProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState({
    passwordUser: false,
    confirmPasswordUser: false,
  });
  const { sessionStore } = useStores();
  const navigate = useNavigate();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [valuePass, setValuePass] = useState<string>('');
  const [checkListArr, setCheckListArr] = useState<any[]>(checks);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      password: "",
      confirmPassword: "",
    },
  });

  const handleChange = (v: string) => {
    setValuePass(v);
  }

  const onSubmit = async (data: PayloadCuenta) => {
    setIsSubmitting(true)
    const body = {
      nombre: data.firstname,
      apellido: data.lastname,
      password: data.password,
    };
    const response = await api.invite.activateInvitation(token, body);
    if (response.token) {
      sessionStore.setToken(response.token);
      api.api.setToken(response.token);
      enqueueSnackbar(Strings.forms.login.success, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      const res = await api.common.getMenuSections();
      if (res)
        navigate(`${res[0].items[0].urlFront}`, {
          state: res[0].items[0].tablero,
        });
    }
    setIsSubmitting(false)
  };

  const handleFocus = () => {
    setOpenPopUp(true);
  };
  const handleBlur = () => {
    setOpenPopUp(false);
  };

  const handleMatch = () => {
    const iterable = pipe(
      lowercaseLetter,
      capitalLetter,
      lessEigthChar,
    )(valuePass);

    const checksListMatched = checks.map((e) => {
      const match = iterable.forEach((v: any) => {
        if (v.key === e.key) return (e.checked = v.checked);
      });
      return { ...e };
    });
    return checksListMatched;
  };

  useEffect(() => {
    setCheckListArr(handleMatch());
  }, [valuePass]);

  const handleClickShowPassword = (
    value: "passwordUser" | "confirmPasswordUser"
  ) => {
    setShowPassword((state) => {
      return { ...state, [value]: !state[value] };
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" gap="16px">
        <TextField
          id="firstname"
          label="Nombre"
          type="text"
          error={Boolean(errors.firstname)}
          helperText={errors.firstname?.message}
          {...register("firstname", { required: { value: true, message: 'Este campo es requerido' }})}
        />
        <TextField
          id="lastname"
          label="Apellido"
          type="text"
          error={Boolean(errors.lastname)}
          helperText={errors.lastname?.message}
          {...register("lastname", { required: { value: true, message: 'Este campo es requerido' }})}
        />
        <TextField
          disabled
          sx={{ marginTop: "5px" }}
          id="email"
          label="Dirección de email"
          value={emailInvitation}
        />
        <Controller 
          control={control}
          name="password"
          defaultValue=""
          rules={validationsRules}
          render={({ field: { value, onChange }}) => (
            handleChange(value),
            <TooltipPassword
              open={openPopUp}
              onClose={() => setOpenPopUp(false)}
              onOpen={() => setOpenPopUp(true)}
              title={<ChekcListPass checksList={checkListArr} />}
              placement='left-start'
              arrow
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <TextField
                onFocus={handleFocus}
                id="password"
                label="Nueva contraseña"
                type={showPassword.passwordUser ? "text" : "password"}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                {...register("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("passwordUser")}
                        edge="end"
                      >
                        {showPassword.passwordUser ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  onBlur: () => handleBlur(),
                  onChange
                }}
              />
            </TooltipPassword>
            )}
          />
          <TextField
            id="confirmPassword"
            label="Repetir nueva contraseña"
            type={showPassword.confirmPasswordUser ? "text" : "password"}
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword?.message}
            {...register("confirmPassword", { 
              required: {value: true, message: 'Este campo es requerido'}, 
              validate: (value) => valuePass === value || 'Las contraseñas no coinciden'
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("confirmPasswordUser")}
                    edge="end"
                  >
                    {showPassword.confirmPasswordUser ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        <Box>
          <Button
            type="submit"
            variant="success"
            color="primary"
            disabled={isSubmitting}
            size="large"
          >
            {
              isSubmitting ? (
                <Box sx={{ marginRight: '5px', width: '57px' }}>
                  <CircularProgress size='25px' color="inherit" />
                </Box>
              )
              :
              'Activar'
            }
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ActivarCuentaForm;
