/* eslint-disable @typescript-eslint/no-explicit-any */
import { types, Instance, SnapshotOut } from "mobx-state-tree";
import { Seccion } from "./navModels";
import api from "services/api";

export const NavModel = types
  .model("Nav")
  .props({
    secciones: types.optional(types.array(Seccion), []),
    isSeccionesLoaded: types.optional(types.boolean, false),
  })
  .views((self) => ({
    itemById(id: number) {
      return self.secciones.find((seccion) =>
        seccion.items ? seccion.items.map((item) => item.id === id) : false
      );
    },
    /**
     * Comprueba si el item existe en el menu según la url del dashboard
     * Si no existe devuelve el primer item del primer menu
     * Si no existe ningún item devuelve null
     */
    itemByUrl(url: string) {
      const items = self.secciones.map((seccion) => seccion.items).flat();
      const urlFinded = items.find((item) =>
        item ? item.urlFront === url : false
      );
      if (!urlFinded && items.length > 0) {
        return items[0];
      }
      return urlFinded ?? null;
    },
  }))
  .actions((self) => {
    // TODO: Add type to secciones param
    function setSecciones(secciones: any) {
      self.secciones.replace(secciones);
      self.isSeccionesLoaded = true;
    }

    return {
      setSecciones,
    };
  })
  .actions((self) => ({
    getSecciones: async () => {
      const response = await api.common.getMenuSections();
      if (!response.kind) {
        self.setSecciones(response);
      }
    },
  }));

type NavModelType = Instance<typeof NavModel>;
export type Nav = NavModelType;
type NavSnapshotType = SnapshotOut<typeof NavModel>;
export type NavSnapshot = NavSnapshotType;
export const createNavDefaultModel = () =>
  types.optional(NavModel, {
    secciones: [],
  });
