import React from "react";
import { Box, IconButton, Card, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Paragraph, Title } from "components/Typography";
import { colors } from "utils/colors";
import { EditPersonalDataForm } from "components/Forms/ModificarDatos";

interface EditPersoanlDataProps {
  open: boolean;
  setOpen: (a: boolean) => void;
  handleCloseMenu: () => void;
  cuentaActual?: string | null;
}

export const EditPersonalData: React.FC<EditPersoanlDataProps> = ({
  open,
  setOpen,
  handleCloseMenu,
  cuentaActual
}) => {
  const onHandleClose = () => {
    setOpen(false);
    handleCloseMenu();
  };

  const stopPropagationForTab = (event: any) => {
    event.stopPropagation();
  };

  return (
    <Modal
      open={open}
      onClose={onHandleClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onKeyDown={stopPropagationForTab}
    >
      <Card sx={{ width: "560px" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "24px",
          }}
        >
          <IconButton
            aria-label="close modal"
            onClick={onHandleClose}
            sx={{
              alignSelf: "flex-end",
              marginRight: "13px",
              marginTop: "13px",
              color: colors.primary.lightGreen,
            }}
            edge="end"
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              marginLeft: "40px",
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Title preset="T1" color={colors.primary.darkGreen}>
              Mis Datos
            </Title>
            <Box sx={{ marginTop: "24px" }}>
              <Paragraph preset="P4" color={colors.neutral.black50}>
                Cuenta
              </Paragraph>
            </Box>
            <Box sx={{ marginBottom: "12px" }}>
              <Title preset="T5" color={colors.primary.darkGreen}>
                {cuentaActual}
              </Title>
            </Box>
          </Box>
          <EditPersonalDataForm setOpen={onHandleClose} />
        </Box>
      </Card>
    </Modal>
  );
};
