import { Box } from "@mui/material";
import styled from "styled-components";

export const TextFieldContainer = styled(Box)`
display: flex;
flex-direction: column;
flex-wrap: nowrap;
align-content: center;
justify-content: center;
align-items: stretch;
margin: 0px 40px 0px 40px;
`