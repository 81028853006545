import { ApiResponse } from "apisauce";
import { Api } from "./api";
import { getGeneralApiProblem } from "./api-problem";

export class MeApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getMeInfo(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(`/me`);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async updateMeInfo(data: any): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.put(
        `/me`,
        data
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data: responseData } = response;
      return responseData;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async patchMeInfo(data: any): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.patch(
        `/me`,
        data
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data: responseData } = response;
      return responseData;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}
