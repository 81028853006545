/* eslint-disable @typescript-eslint/no-explicit-any */

const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: import.meta.env.VITE_ENVIRONMENT || "development",
  payload: {
    environment: import.meta.env.VITE_ENVIRONMENT || "development",
    // context: 'rollbar/test'
    client: {
      javascript: {
        code_version: "1.0",
        source_map_enabled: true,
        guess_uncaught_frames: true
      },
    },
  },
  // transform: function(payload: any) {
  //   const trace = payload.body.trace;
  //   // Change 'yourdomainhere' to your domain.
  //   const locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+\.yourdomainhere\.com(.*)/;
  //   if (trace && trace.frames) {
  //     for (let i = 0; i < trace.frames.length; i++) {
  //       const filename = trace.frames[i].filename;
  //       if (filename) {
  //         const m = filename.match(locRegex);
  //         // Be sure that the minified_url when uploading includes 'dynamichost'
  //         trace.frames[i].filename = m[1] + '://dynamichost' + m[2];          
  //       }
  //     }
  //   }
  // },
  // ignoredMessages: ["ResizeObserver loop limit exceeded"],
  checkIgnore: (isUncaught: any, args: any, payload: any) =>
    payload?.err?.status === 401,
};

export default rollbarConfig;