import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PrivateRoute from "components/Private";
import Dashboards from "screen/main/dashboards";
import MainLayout from "components/Layouts/Main";
import { useStores } from "store/root-store/root-store-context";
import { AlertTknExpired } from "components/modals/tokenExpired";
import { observer } from "mobx-react";

const Main: React.FC = () => {
  const { sessionStore, navStore } = useStores();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const pathSavedFromStore = navStore.secciones.map(e => e.items).flat().map(e => e?.urlFront);
  const isMatchingPath = pathSavedFromStore.includes(currentPath);

  const navigateTo = () => {
    if(isMatchingPath) return null;
    if(navStore.secciones[0]) return navigate(`${navStore.secciones[0].items[0]?.urlFront}`, {
      state: navStore.secciones[0].items[0]?.tablero,
    });
  }

  useEffect(() => {
    if (sessionStore.token) {
      sessionStore.getMeUser();
      navStore.getSecciones();
      navigateTo();
    }
  }, [sessionStore.token, currentPath]);


  return (
    <PrivateRoute>
      <>
        <AlertTknExpired />
        <MainLayout>
          <Routes>
            <Route path="/*" element={<Dashboards />} />
          </Routes>
        </MainLayout>
      </>
    </PrivateRoute>
  );
};

export default observer(Main);
