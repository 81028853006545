/* eslint-disable no-useless-escape */

export const ColorByCheckList = (progress: number, colors: any): string => {
  return progress > 99 ? colors.complementaryGreen : progress >= 40 && progress <= 99 ? colors.complementaryYellow : colors.complementaryError
}

export const lessEigthChar = (value: string) => ({
  checked: value.length >= 8,
  key: 'lessEigthChar'
})

export const specialChar = (value: string) => ({
  checked: /[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/g.test(value),
  key: 'specialChar'
})

export const hasNumber = (value: string) => ({
  checked: /\d/g.test(value),
  key: 'hasNumber'
})

export const capitalLetter = (value: string) => {
  let i = 0
  if (value.length === 0) {
    return { checked: false, key: 'capitalLetter' }
  }
  while (i < value.length) {
    const character = value.charAt(i)
    if (character == character.toLowerCase()) {
      // Character is lowercase, numeric, or a symbol
    } else if (character == character.toUpperCase()) {
      return { checked: true, key: 'capitalLetter' }
    }
    i++
  }
  return { checked: false, key: 'capitalLetter' }
}

export const lowercaseLetter = (value: string) => {
  let i = 0
  if (value.length === 0) {
    return { checked: false, key: 'lowercaseLetter' }
  }
  while (i < value.length) {
    const character = value.charAt(i)
    if (character == character.toUpperCase()) {
      // Character is lowercase, numeric, or a symbol
    } else if (character == character.toLowerCase()) {
      return { checked: true, key: 'lowercaseLetter' }
    }
    i++
  }
  return { checked: false, key: 'lowercaseLetter' }
}

export const checks = [
  {
    label: '8 caracteres',
    checked: true,
    value: 20,
    key: 'lessEigthChar'
  },
  {
    label: '1 mayúscula',
    checked: true,
    value: 20,
    key: 'capitalLetter'
  },
  {
    label: '1 minúscula',
    checked: false,
    value: 20,
    key: 'lowercaseLetter'
  }
]


export const pipe = (...fns: any[]) => (x: string) => fns.map(e => e(x))

export const validationsRules = { 
  required: {value: true, message: 'Este campo es requerido'}, 
  minLength: {value: 8, message: 'La contraseña debe tener al menos 8 caracteres'}, 
  validate: {
    upperAndLower: (value: string) => capitalLetter(value).checked || 'La contraseña debe tener al menos una mayúscula',
    lower: (value: string) => lowercaseLetter(value).checked || 'La contraseña debe tener al menos una minúscula',
  }          
}

export const contentOptions = {
  locale: "es-ES",

}

export const frameOptions = (url: string, dashboardRef: any) => ({
  url,
  container: dashboardRef.current || "",
  scrolling: "no",
  height: "100%",
  resizeHeightOnSizeChangedEvent: true,
  width: "100%",
  undoRedoDisabled: true,
  resetDisabled: true,
  footerPaddingEnabled: true,
})