import React from "react";
import { observer } from "mobx-react";
import { Box, ListItemButton } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Title } from "components/Typography";
import { colors } from "utils/colors";

interface INavItemDivisor {
  open: boolean;
  title: string;
  icon: string;
  collapsed: boolean;
  handleClick: () => void;
}

const NavItemDivisor: React.FC<INavItemDivisor> = observer(
  ({ open, title, icon, collapsed, handleClick }) => {
    return (
      <ListItemButton
        onClick={handleClick}
        sx={{
          display: "flex",
          justifyContent: open ? "space-between" : "center",
          backgroundColor: colors.neutral.black30,
        }}
      >
        <Box
          display="flex"
          justifyContent={open ? "flex-start" : "center"}
          alignItems="center"
          gap={1}
        >
          {icon && (
            <Box width="24px" height="24px">
              <img
                src={icon}
                alt={title}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          )}
          {open && (
            <Title preset="Caps" color={colors.neutral.black70}>
              {title.toUpperCase()}
            </Title>
          )}
        </Box>
        {open && collapsed && (
          <ExpandLessIcon sx={{ color: colors.neutral.black50 }} />
        )}
        {open && !collapsed && (
          <ExpandMoreIcon sx={{ color: colors.neutral.black70 }} />
        )}
      </ListItemButton>
    );
  }
);

export default NavItemDivisor;
