import React from 'react';
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { colors } from 'utils/colors';

export const TooltipPassword = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.neutral.white,
    color: colors.neutral.black,
    border: '0.5px solid gray',
    width: '250px',
    height: 'auto',
    borderRadius: '5px'
  },
}));
