import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import EditPassword from "components/modals/editPassword";
import { Title } from "components/Typography";
import { colors } from "utils/colors";
import { useStores } from "store/root-store/root-store-context";
import { useSnackbar } from "notistack";
import { Strings } from "constants/strings";
import { rootStore } from "store/root-store/root-store";
import { EditPersonalData } from "components/commons/dashboard/ModificarDatosCard";
import api from "services/api";
import { useNavigate } from "react-router-dom";

interface IMenuProfile {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
}

/**
 * Para usar este componente, tiene que existir un botón que abra el menú.
 * El botón debe tener un evento onClick que abra el menú. Por ejemplo:
 *
 * ```tsx
 * const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
 * const open = Boolean(anchorEl);
 * const handleClick = (event: React.MouseEvent<HTMLElement>) => {
 *  setAnchorEl(event.currentTarget);
 * };
 * const handleClose = () => {
 *  setAnchorEl(null);
 * };
 *
 * // En el JSX
 * <Button onClick={handleClick}>Abrir menú</Button>
 * <MenuProfile anchorEl={anchorEl} open={open} onClose={handleClose} />
 * ```
 */
const MenuProfile: React.FC<IMenuProfile> = ({ anchorEl, open, onClose }) => {
  const [openModal, setOpen] = useState(false);
  const { sessionStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [openPersonalDataModal, setOpenPersonalData] = useState(false);
  const handleLogout = async () => {
    const response = await api.auth.logout();
    if (!response.ok) {
      enqueueSnackbar(Strings.notifications.commonError, {
        variant: "error",
      });
    }
    sessionStore.logOut();
    rootStore.resetStore();
    navigate("/portal/iniciar-sesion", { replace: true});
  };

  return (
    <Menu
      id="menu-profile"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      elevation={3}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: colors.neutral.black30,
        },
      }}
    >
      <EditPassword
        open={openModal}
        setOpen={setOpen}
        handleCloseMenu={onClose}
      />
      <EditPersonalData
        open={openPersonalDataModal}
        setOpen={setOpenPersonalData}
        handleCloseMenu={onClose}
        cuentaActual={sessionStore.currentUser?.cuentaNombre}
      />
      <MenuItem onClick={() => setOpenPersonalData(true)}>
        <Title preset="T6" color={colors.neutral.black70}>
          Modificar datos
        </Title>
      </MenuItem>
      <MenuItem onClick={() => setOpen(true)}>
        <Title preset="T6" color={colors.neutral.black70}>
          Modificar contraseña
        </Title>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <Title preset="T6" color={colors.neutral.black70}>
          Cerrar sesión
        </Title>
      </MenuItem>
    </Menu>
  );
};

export default MenuProfile;
