/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Box, TextField, Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { TextFieldContainer } from "./index.styled";
import { Paragraph } from "components/Typography";
import { colors } from "utils/colors";
import { observer } from "mobx-react";
import { useStores } from "store/root-store/root-store-context";
import api from "services/api";
import { useSnackbar } from "notistack";
import { Strings } from "constants/strings";

type FormDataPatch = {
  nombre: string;
  apellido: string;
  email: string;
};

interface EditPersonalDataFormProps {
  setOpen: () => void;
}

export const EditPersonalDataForm: React.FC<EditPersonalDataFormProps> =
  observer(({ setOpen }) => {
    const { sessionStore } = useStores();
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (data: FormDataPatch) => {
      setIsSubmitting(true);
      const body = {
        firstName: data.nombre,
        lastName: data.apellido,
      };
      const response = await api.me.patchMeInfo(body);

      if (!response.kind) {
        enqueueSnackbar(Strings.notifications.success, {
          variant: "success",
        });
        sessionStore.getMeUser();
        setOpen();
      } else {
        enqueueSnackbar(Strings.notifications.commonError, {
          variant: "error",
        });
      }
      setIsSubmitting(false);
    };

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      defaultValues: {
        nombre: sessionStore.currentUser?.firstName ?? "",
        apellido: sessionStore.currentUser?.lastName ?? "",
        email: sessionStore.currentUser?.email ?? "",
      },
    });

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextFieldContainer>
          <TextField
            id="password"
            sx={{ mb: "20px" }}
            label="Nombre"
            placeholder="Nombre"
            type="text"
            error={Boolean(errors.nombre)}
            helperText={errors.nombre ? String(errors.nombre?.message) : ""}
            {...register("nombre")}
          />

          <TextField
            id="password"
            sx={{ mb: "20px" }}
            label="Apellido"
            placeholder="Apellido"
            type="text"
            error={Boolean(errors.apellido)}
            helperText={errors.apellido ? String(errors.apellido?.message) : ""}
            {...register("apellido")}
          />
          <TextField
            id="password"
            label="Dirección de email"
            placeholder="Dirección de email"
            type="email"
            disabled
            {...register("email")}
          />
          <Box sx={{ mb: "20px", mt: "5px" }}>
            <Paragraph preset="P4" color={colors.neutral.black50}>
              El email al que fuiste invitado no puede ser modificado
            </Paragraph>
          </Box>
        </TextFieldContainer>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <Button
            type="button"
            variant="critic"
            color="secondary"
            sx={{ padding: "12px" }}
            onClick={() => setOpen()}
          >
            Descartar
          </Button>
          <Button
            type="submit"
            variant="success"
            color="primary"
            sx={{ padding: "12px" }}
            disabled={isSubmitting}
          >
            {
              isSubmitting ? (
                <Box sx={{ marginRight: '5px', width: '110px' }}>
                  <CircularProgress size='25px' color="inherit" />
                </Box>
              )
              :
              'Guardar cambios'
            }
          </Button>
        </Box>
      </form>
    );
  });
