import React from "react";
import { Box } from "@mui/material";
import Nav from "components/Nav";
import Footer from "components/Footer";

interface IMainLayout {
  children: React.ReactNode;
}

const MainLayout: React.FC<IMainLayout> = ({ children }) => {
  return (
    <Box display="flex" maxWidth="100vw">
      <Nav />
      <Box
        width="100%"
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {children}
        <Footer />
      </Box>
    </Box>
  );
};

export default MainLayout;
