import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "store/root-store/root-store-context";

interface IPrivateRoute {
  children: JSX.Element;
}

function PrivateRoute({ children }: IPrivateRoute) {
  const location = useLocation();
  const { sessionStore } = useStores();

  if (sessionStore.isLogged && sessionStore.token) {
    return children;
  }

  return (
    <Navigate to="/portal/iniciar-sesion" state={{ from: location }} replace />
  );
}

export default observer(PrivateRoute);
